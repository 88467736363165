export default {
  app: {
    apiUrl: process.env.REACT_APP_API_BASE_URL || "https://stem.goswivt.com",
    dev: process.env.NODE_ENV === "development",
    uploadUrl: process.env.REACT_APP_API_BASE_URL || "https://stem.goswivt.com",
    imageUrlLocal: process.env.REACT_APP_PUBLIC_APP_IMAGE_URL || "/api/public",
    // joystickAPIKey: "yNSy1BEy7zlzXwD5qk9s1v8COCWJpxW0", // client api key
  //  joystickAPIKey: "nhz4KDfuuf4tijXvOqE4sm2N65z7BCxQ", // my testing api key
joystickAPIKey: "JXeVwW6v84JeUDOyb6PzcZLC3wVqxqqB", 
  joystickApiBaseUrl: "https://api.getjoystick.com/api/v1/config",
    awsBaseUrl:
      process.env.REACT_APP_PUBLIC_APP_AWS_BASE_URL ||
      "https://stemunicorn-dev-buck.s3.amazonaws.com/",
  },
};
